import { BLOCKS } from "@contentful/rich-text-types"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"
import Divider from "../components/common/Divider"
import Layout from "../components/common/Layout"
import PostCard from "../components/blog/PostCard"
import Container from "../components/common/Container"
import Button from "../components/common/Button"
import SEO from "../components/common/SEO"
import { LIGHT_THEME } from "../theme"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import FacebookSocial from "../assets/images/social-media-square-facebook.inline.svg"
import LinkedinSocial from "../assets/images/social-media-square-linkedin.inline.svg"
import TwitterSocial from "../assets/images/social-media-square-twitter.inline.svg"
import { DEVICES } from "../config"
import styled, { css } from "styled-components"

const ShareButton = styled(Button.Raised).attrs({ as: "a" })`
  outline: none;
`

const StyledImg = ({ title, fit = "", ...props }) => (
  <div
    css={`
      padding: 10px;
      border-radius: 6px;
      box-shadow: inset -2px -2px 4px 0 var(--color-shadow-one),
        inset 2px 2px 4px 0 var(--color-shadow-two);
      background-color: var(--main);
      margin-bottom: 40px;
    `}
  >
    <Img
      css={`
        width: 100%;
        ${fit === "cover"
          ? css`
              height: 406px;
              object-fit: cover;
            `
          : "height: auto;"}
        border-radius: 4px;
      `}
      {...props}
    />
    {title && (
      <div
        css={`
          box-shadow: -5px -5px 10px 0px #fffefe,
            5px 5px 10px 0px rgba(25, 25, 25, 0.2);
          padding: 10px;
          margin-top: 10px;
          border-radius: 6px;
          opacity: 0.55;
          font-size: 16px;
          line-height: 24px;
        `}
      >
        {title}
      </div>
    )}
  </div>
)

const options = {
  renderNode: {
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            id: `sources-${index + 1}`,
          })
        })}
      </ol>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => <StyledImg {...node.data.target} />,
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1
        css={`
          font-size: 32px;
          line-height: 1.5;
          margin-top: 32px;
          margin-bottom: 16px;
        `}
      >
        {children}
      </h1>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2
        css={`
          font-size: 24px;
          line-height: 1.333;
          margin-top: 32px;
          margin-bottom: 16px;
        `}
      >
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3
        css={`
          font-size: 20px;
          line-height: 1.4;
          margin-top: 32px;
          margin-bottom: 16px;
        `}
      >
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4
        css={`
          font-size: 18px;
          line-height: 1.333;
          margin-top: 32px;
          margin-bottom: 16px;
        `}
      >
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <h5
        css={`
          font-size: 14px;
          line-height: 1.1428;
        `}
      >
        {children}
      </h5>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <h6
        css={`
          font-size: 12px;
          line-height: 1.17;
        `}
      >
        {children}
      </h6>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <p
          css={`
            opacity: 0.55;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: normal;
            margin-bottom: 16px;

            a {
              color: #1979ff;
              transition: color 250ms ease;

              &:hover {
                color: #0095ff;
              }
            }
          `}
        >
          {children}
        </p>
      )
    },
  },
}

function BlogPost({ data, pageContext, location }) {
  const post = data.contentfulBlogPost
  const relatedPosts = data.relatedPosts.edges.map(edge => edge.node)

  return (
    <Layout theme={LIGHT_THEME}>
      <SEO title={post.title} />
      <Divider
        css={`
          margin-bottom: 56px;
        `}
      />
      <Container
        compact
        as="main"
        css={`
          padding-bottom: 10rem;
        `}
      >
        <div
          css={`
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-bottom: 13px;
          `}
        >
          <div>{post.date}</div>
          {/* <div
            css={`
              margin: 0 12px;
              opacity: 0.55;
            `}
          >
            |
          </div>
          <div
            css={`
              opacity: 0.55;
            `}
          >
            {post.categories.join(", ")}
          </div> */}
        </div>
        <h1
          css={`
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 16px;
            max-width: 631px;
          `}
        >
          {post.title}
        </h1>
        {post.description && (
          <h3
            css={`
              max-width: 709px;
              opacity: 0.55;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              margin-bottom: 40px;
            `}
          >
            {post.description.description}
          </h3>
        )}
        <StyledImg fit="cover" fluid={post.widerMedia?.fluid} />
        <div
          css={`
            max-width: 853px;
          `}
        >
          {post.content && renderRichText(post.content, options)}
        </div>
        {post.sources && (
          <h2
            css={`
              margin-top: 32px;
              margin-bottom: 16px;
            `}
          >
            Sources
          </h2>
        )}
        {post.sources && renderRichText(post.sources, options)}
        <Divider
          css={`
            margin: 40px 0;
          `}
        />
        <div
          css={`
            display: flex;
            align-items: center;

            .react-share__ShareButton {
              outline: none;

              &:hover {
                ${ShareButton} {
                  opacity: 1;
                }
              }

              &:not(:last-child) {
                margin-right: 14px;
              }
            }

            ${ShareButton} {
              opacity: 0.6;

              padding: 8px 16px;

              .auto-svg {
                width: 24px;
                height: 24px;
                margin-left: auto;
                margin-right: 0;
              }
            }

            @media (max-width: ${DEVICES.tablet}px) {
              flex-direction: column;

              .react-share__ShareButton,
              ${ShareButton} {
                width: 100%;
              }

              .react-share__ShareButton {
                &:not(:last-child) {
                  margin-right: unset;
                  margin-bottom: 14px;
                }
              }
            }
          `}
        >
          <div
            css={`
              margin-right: 24px;
              opacity: 0.55;

              @media (max-width: ${DEVICES.tablet}px) {
                margin-right: unset;
                margin-bottom: 24px;
              }
            `}
          >
            Share this article
          </div>
          <LinkedinShareButton url={location.href} title={post.title}>
            <ShareButton>
              Share <LinkedinSocial />
            </ShareButton>
          </LinkedinShareButton>
          <FacebookShareButton url={location.href} title={post.title}>
            <ShareButton>
              Share <FacebookSocial />
            </ShareButton>
          </FacebookShareButton>
          <TwitterShareButton
            url={location.href}
            title={post.title}
            via="pnoe_fitness"
          >
            <ShareButton>
              Tweet <TwitterSocial />
            </ShareButton>
          </TwitterShareButton>
        </div>
        <Divider
          css={`
            margin: 40px 0;
          `}
        />
        <h2>More Articles like this</h2>
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 24px;
            margin-top: 40px;
          `}
        >
          {relatedPosts.map(post => (
            <PostCard key={post.contentful_id} post={post} />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PostById($id: String!, $categories: [String]!) {
    contentfulBlogPost(contentful_id: { eq: $id }) {
      contentful_id
      title
      slug
      description {
        description
      }
      categories
      content {
        raw
        references {
          ... on ContentfulAsset {
            title
            contentful_id
            __typename
            fluid(maxWidth: 2560) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      sources {
        raw
      }
      date(formatString: "MMMM DD, yyyy")
      widerMedia {
        contentful_id
        fluid(maxWidth: 2560) {
          src
        }
      }
    }
    relatedPosts: allContentfulBlogPost(
      limit: 3
      filter: { categories: { in: $categories }, contentful_id: { ne: $id } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          contentful_id
          title
          slug
          categories
          content {
            raw
          }
          date(formatString: "MMMM DD, yyyy")
          widerMedia {
            contentful_id
            fluid {
              src
            }
          }
        }
      }
    }
  }
`

export default BlogPost
